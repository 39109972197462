import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { SiteLink } from '../../../components/SiteLink';
import { Box } from '@twilio-paste/box';
import { Paragraph } from '@twilio-paste/paragraph';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { Disclosure, DisclosureContent, DisclosureHeading } from '@twilio-paste/disclosure';
import { version as coreVersion, dependencies as coreDependencies } from '@twilio-paste/core/package.json';
import { version as iconsVersion } from '@twilio-paste/icons/package.json';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { InlineCode } from '../../../components/Typography';
import Package from '../../../../../../package.json';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/getting-started/engineering/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Quickstart`}</h2>
        <p>{`The quickest way to get started with Paste is to bootstrap a new React app using one of our templates for popular React frameworks, `}<a parentName="p" {...{
            "href": "https://nextjs.org/"
          }}>{`Next.js`}</a>{` and `}<a parentName="p" {...{
            "href": "https://create-react-app.dev/"
          }}>{`Create React App`}</a>{`.`}</p>
        <p>{`Both approaches will create a new React app with Paste installed and ready for development.`}</p>
        <h3>{`Using create-next-app`}</h3>
        <p>{`Execute `}<a parentName="p" {...{
            "href": "https://github.com/vercel/next.js/tree/canary/packages/create-next-app"
          }}><inlineCode parentName="a">{`create-next-app`}</inlineCode></a>{` with `}<a parentName="p" {...{
            "href": "https://docs.npmjs.com/cli/init"
          }}>{`npm`}</a>{` or `}<a parentName="p" {...{
            "href": "https://yarnpkg.com/lang/en/docs/cli/create/"
          }}>{`Yarn`}</a>{` to bootstrap a Next.js app using Paste:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`npx create-next-app --example https://github.com/twilio-labs/paste/tree/main/packages/paste-nextjs-template my-paste-app
# or
yarn create next-app --example https://github.com/twilio-labs/paste/tree/main/packages/paste-nextjs-template my-paste-app
`}</code></pre>
        <h3>{`Using create-react-app`}</h3>
        <p>{`To create a new CRA app using the Paste template execute the following command:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`npx create-react-app --template @twilio-paste my-paste-app
# or
yarn create react-app --template @twilio-paste my-paste-app
`}</code></pre>
        <h2>{`Manual Installation`}</h2>
        <h3>{`Dependencies`}</h3>
        <p>{`To start using Paste, you must first install the following third party dependencies:`}</p>
        <Box marginBottom="space60" mdxType="Box">
  <Table tableLayout="fixed" mdxType="Table">
    <THead mdxType="THead">
      <Tr mdxType="Tr">
        <Th mdxType="Th">Package</Th>
        <Th mdxType="Th">Version</Th>
      </Tr>
    </THead>
    <TBody mdxType="TBody">
      <Tr mdxType="Tr">
        <Td mdxType="Td">react</Td>
        <Td mdxType="Td">{Package.dependencies['react']}</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">react-dom</Td>
        <Td mdxType="Td">{Package.dependencies['react-dom']}</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">prop-types</Td>
        <Td mdxType="Td">{Package.dependencies['prop-types']}</Td>
      </Tr>
    </TBody>
  </Table>
        </Box>
        <p>{`Install them in your project folder using either of these terminal commands:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`# yarn
yarn add react react-dom prop-types

# npm
npm install react react-dom prop-types
`}</code></pre>
        <h3>{`Paste Packages`}</h3>
        <p>{`Paste is split into two main packages: `}<inlineCode parentName="p">{`@twilio-paste/core`}</inlineCode>{` and `}<inlineCode parentName="p">{`@twilio-paste/icons`}</inlineCode>{`. Everything provided by Paste
is included into those two packages. A key distinction with Paste Core is that we `}<em parentName="p">{`include`}</em>{` - not `}<em parentName="p">{`bundle`}</em>{` - all of Paste; this means we expose sub-packages you can individually import.
For example, you would install `}<inlineCode parentName="p">{`@twilio-paste/core`}</inlineCode>{` and import the Button component from `}<inlineCode parentName="p">{`@twilio-paste/core/button`}</inlineCode>{` rather than directly from `}<inlineCode parentName="p">{`@twilio-paste/core`}</inlineCode>{`.
This keeps your app lean by only including the code you use. You can `}<a parentName="p" {...{
            "href": "#how-the-core-package-stays-lean"
          }}>{`read about how Paste keeps your bundles small`}</a>{` down below.`}</p>
        <p>{`Everything exposed inside Paste Core can also be installed individually; you could install `}<inlineCode parentName="p">{`@twilio-paste/button`}</inlineCode>{` and import only that one package.
However, we recommend using the Core package for ease of maintenance and only falling back to the individual packages if you need to pin to a specific version.`}</p>
        <Box marginBottom="space30" mdxType="Box">
  <Table tableLayout="fixed" mdxType="Table">
    <THead mdxType="THead">
      <Tr mdxType="Tr">
        <Th mdxType="Th">Package</Th>
        <Th mdxType="Th">Version</Th>
      </Tr>
    </THead>
    <TBody mdxType="TBody">
      <Tr mdxType="Tr">
        <Td mdxType="Td">@twilio-paste/core</Td>
        <Td mdxType="Td">{coreVersion}</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">@twilio-paste/icons</Td>
        <Td mdxType="Td">{iconsVersion}</Td>
      </Tr>
    </TBody>
  </Table>
        </Box>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`# yarn
yarn add @twilio-paste/core @twilio-paste/icons

# npm
npm install @twilio-paste/core @twilio-paste/icons
`}</code></pre>
        <h3>{`Setting up the Theme Provider`}</h3>
        <p>{`For Paste components to be styled correctly, you must provide them with a theme.
To do so you must wrap the root of your app with the theme provider and select a theme.
We recommend the default theme.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Theme} from '@twilio-paste/core/theme';

// Wrap your root component with the Theme.Provider like so:
const App = () => (
  <Theme.Provider theme="default">
    <RestOfYourAppInHere />
  </Theme.Provider>
);
`}</code></pre>
        <h2>{`Usage`}</h2>
        <h3>{`Paste Components`}</h3>
        <p>{`You can use the Paste components anywhere that is wrapped by the `}<a parentName="p" {...{
            "href": "#setting-up-the-theme-provider"
          }}>{`Theme Provider`}</a>{`.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Box} from '@twilio-paste/core/box';

const Component = () => (
  <Box margin="space20" backgroundColor="colorBackground">
    Hello Paste!
  </Box>
);
`}</code></pre>
        <h3>{`Using Icons`}</h3>
        <p>{`The Paste Icons package provides icon components. They can be used to enhance the appearance of a web interface and break
up the monotony of text. Icons have to be imported individually.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`// or from '@twilio-paste/icons/cjs/PlusIcon'
import {PlusIcon} from '@twilio-paste/icons/esm/PlusIcon';

const Component = () => (
  <Button variant="secondary" size="small" onClick={() => {}}>
    <PlusIcon decorative={true} />
    Add to cart
  </Button>
);
`}</code></pre>
        <p>{`For more information about our icon system, checkout our `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines"
          }}>{`usage guide here`}</a>{`.`}</p>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    You can view and play with icons on <SiteLink to="/icons" mdxType="SiteLink">our icon list</SiteLink>. Our icon set is still limited,
    and more will be added over time. Please{' '}
    <Anchor href="https://github.com/twilio-labs/paste/issues" mdxType="Anchor">file an icon request</Anchor> or follow the guide on{' '}
    <SiteLink to="/icons/how-to-add-an-icon" mdxType="SiteLink">how to add an icon</SiteLink>.
  </CalloutText>
        </Callout>
        <h3>{`Jest / Testing`}</h3>
        <p>{`Just like rendering Paste components in a web application, when rendering components for the purposes of testing you
are required to wrap them in the `}<a parentName="p" {...{
            "href": "#setting-up-the-theme-provider"
          }}>{`Theme Provider`}</a>{`. If you do not, your components may fail to render correctly.`}</p>
        <p>{`It can sometimes be helpful to test Paste components without animation. To disable animations on Paste components, pass the `}<inlineCode parentName="p">{`disableAnimations`}</inlineCode>{` boolean prop to the `}{`<`}{`Theme.Provider`}{`>`}{`.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Theme.Provider theme="default" disableAnimations>
  {/* your content */}
</Theme.Provider>
`}</code></pre>
        <h4>{`Handling Icons in Jest`}</h4>
        <p>{`Jest will often complain about using the esModules versions (`}<inlineCode parentName="p">{`/esm/`}</inlineCode>{`) of Paste Icons, since it perfers the commonJS version. You'll often see an error similar to:`}</p>
        <pre><code parentName="pre" {...{}}>{`Test suite failed to run
  unknown: Unexpected token (1:660)
    This usually means that you are trying to import a file which
    Jest cannot parse, e.g. it's not plain JavaScript.
    ...
`}</code></pre>
        <p>{`In order to fix these Jest errors, you'll need to configure Jest to correctly handle the esModules versions of Paste Icons.`}</p>
        <p>{`You can use the `}<inlineCode parentName="p">{`transformIgnorePatterns`}</inlineCode>{` option to tell Jest to not transform Paste Icons, which allows Jest to transpile these files. More
information about `}<inlineCode parentName="p">{`transformIgnorePatterns`}</inlineCode>{` can be found by `}<a parentName="p" {...{
            "href": "https://jestjs.io/docs/en/configuration#transformignorepatterns-arraystring"
          }}>{`reading the Jest documentation`}</a>{`.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`// jest.config.js
{
  "transformIgnorePatterns": [
    "/node_modules/@twilio-paste/icons/.*"
  ]
}
`}</code></pre>
        <h3>{`Global Styles`}</h3>
        <p>{`Many apps/websites utilize global stylesheets. Even though Paste styles are scoped at the component level, global styles can creep
in and cause some havoc. Make sure to thoroughly test Paste components to verify everything looks as they should.`}</p>
        <h3>{`Fonts`}</h3>
        <h4>{`Default theme`}</h4>
        <p>{`Fonts for the default theme are available via the Twilio CDN and published from an `}<a parentName="p" {...{
            "href": "https://code.hq.twilio.com/DSYS/paste-fonts"
          }}>{`internal git repository`}</a>{`.`}</p>
        <p>{`The best and most performant way to load the fonts is to include the following snippet in the `}<inlineCode parentName="p">{`<head />`}</inlineCode>{` of your web page.`}</p>
        <pre><code parentName="pre" {...{}}>{`<link rel="preconnect" href="https://assets.twilio.com" crossorigin />
<link rel="stylesheet" href="https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fonts.css">
`}</code></pre>
        <p>{`Alternatively, Paste will automatically load the fonts via JavaScript, so long as you wrap your application with the `}<inlineCode parentName="p">{`Theme.Provider`}</inlineCode>{` and select the `}<inlineCode parentName="p">{`default`}</inlineCode>{` theme. Though, you should note that this is not the most performant way.`}</p>
        <h4>{`Other themes`}</h4>
        <p>{`If you are using `}<strong parentName="p">{`any other theme`}</strong>{`, Paste leaves it up to you to load the fonts needed. Console uses Whitney ScreenSmart
and SendGrid uses Colfax. More often than not with those themes, you are working within existing SendGrid and Twilio Console applications
and these fonts are automatically loaded for you.`}</p>
        <p>{`The Whitney font is loaded by the Typography.com service and is only allowed on `}{`*`}{`.twilio.com domains as well as localhost.
Make sure to serve your app from the correct hostname if you're having issues with font loading.`}</p>
        <p>{`If you are not working in an existing Twilio Console experience, you can include the following link element in your sites `}<inlineCode parentName="p">{`head`}</inlineCode>{`
to load these fonts if you are serving your application from `}{`*`}{`.twilio.com domains.`}</p>
        <pre><code parentName="pre" {...{}}>{`<link rel="stylesheet" type="text/css" href="//cloud.typography.com/6230892/752864/css/fonts.css">
`}</code></pre>
        <h2>{`Additional information`}</h2>
        <h3>{`Core package list`}</h3>
        <p>{`You can install Paste packages individually to pin to a specific version in your project.
Here's an list of all the packages included inside Paste Core:`}</p>
        <Box marginBottom="space130" mdxType="Box">
  <Disclosure mdxType="Disclosure">
    <DisclosureHeading as="h4" variant="heading50" mdxType="DisclosureHeading">
      Show full package list
    </DisclosureHeading>
    <DisclosureContent mdxType="DisclosureContent">
      <Table tableLayout="fixed" mdxType="Table">
        <THead mdxType="THead">
          <Tr mdxType="Tr">
            <Th mdxType="Th">Package</Th>
            <Th mdxType="Th">Version</Th>
          </Tr>
        </THead>
        <TBody mdxType="TBody">
          {Object.keys(coreDependencies).map((dependency, index) => {
                    return <Tr key={`core-dep-${dependency}-${index}`} mdxType="Tr">
                <Td mdxType="Td">{dependency}</Td>
                <Td mdxType="Td">{coreDependencies[dependency]}</Td>
              </Tr>;
                  })}
        </TBody>
      </Table>
    </DisclosureContent>
  </Disclosure>
        </Box>
        <h3>{`How the Core package stays lean`}</h3>
        <p>{`Unlike other bundled libraries, using Core and Icons won't load `}<em parentName="p">{`all of`}</em>{` Paste into your product's bundle, it will only include the parts you are importing.
This happens automatically, without any requirement for a tree-shaking config in your builds.`}</p>
        <p>{`You may have imported from other libraries like this:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Box, Anchor, Button, Theme} from '@twilio-paste/core';
import {DisclosureIcon, DeleteIcon} from '@twilio-paste/icons';
`}</code></pre>
        <p>{`The problem with importing this way is that the `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/blob/main/packages/paste-core/components/button/package.json#L10"
          }}>{`referenced "main" file`}</a>{`
in these packages has to bundle all the different pieces together. This causes everything to be loaded even if we only need one small feature.
With over 50 icons in our Icon package and 40 components in Core - with more being added continuously - this can become very heavy very quickly.`}</p>
        <p>{`In Paste, we use an "unbarreled" import mechanism, so the above would look like this:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Box} from '@twilio-paste/core/box';
import {Anchor} from '@twilio-paste/core/anchor';
import {Button} from '@twilio-paste/core/button';
import {Theme} from '@twilio-paste/core/theme';
import {DisclosureIcon} from '@twilio-paste/icons/esm/DisclosureIcon';
import {DeleteIcon} from '@twilio-paste/icons/esm/DeleteIcon';
`}</code></pre>
        <p>{`While this has more import lines than the previous example, we are specifying the import to a limited package. This keeps your bundles lean by only loading exactly what you need.`}</p>
        <p>{`Paste Core is able to do this because Paste publishes each library and component as its own package. For example the above can be written as:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Box} from '@twilio-paste/box';
import {Anchor} from '@twilio-paste/anchor';
import {Button} from '@twilio-paste/button';
import {Theme} from '@twilio-paste/theme';
// Paste Icons are not individually published
import {DisclosureIcon} from '@twilio-paste/icons/esm/DisclosureIcon';
import {DeleteIcon} from '@twilio-paste/icons/esm/DeleteIcon';
`}</code></pre>
        <p>{`The reason we recommend Core over using individual package imports is because it makes maintaining Paste in your project easier. If using the above approach, your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file will be full of individual package dependencies
and keeping their versions in sync can be quite time consuming.`}</p>
        <p>{`However, the individual package import is invaluable for when updating Core also makes changes to a package you aren't ready for. You can then install that pinned package and import from it directly.
For example let's assume we update our Core package and as a side effect, the Button component gets updated. We aren't ready to update Button, but that shouldn't stop us from updating the rest of Core! We can install `}<inlineCode parentName="p">{`@twilio-paste/button`}</inlineCode>{` to the version that works and import it like this:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Box} from '@twilio-paste/core/box';
import {Anchor} from '@twilio-paste/core/anchor';
import {Button} from '@twilio-paste/button'; // This loads the version we installed
import {Theme} from '@twilio-paste/core/theme';
`}</code></pre>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      